@font-face {
  font-family: 'OpenSans_Condensed-Regular';
  src: local('OpenSans_Condensed-Regular'),
    url(./fonts/OpenSans_Condensed-Regular.ttf) format('truetype');
}

a:link {
  text-decoration: none;
}


.homepageLink {
  color: #686de0;
}

.termLinks {
  color: #686de0;
}

.sitemapLinks {
  color: #686de0;
}

.plansButton {
  color: #EFF3F7;
  text-align: center;
  letter-spacing: 0.5px;
  background-color: transparent;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: #eff3f7;
  justify-content: center;
  align-items: center;
  padding: 24px 30px;
  font-family: 'OpenSans_Condensed-Regular';
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  box-shadow: 6px 6px rgba(125, 125, 125, 0.9);
  cursor: pointer;
}

.CTAButton {
  color: #EFF3F7;
  text-align: center;
  letter-spacing: 0.5px;
  background-color: #080066;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: #080066;
  justify-content: center;
  align-items: center;
  padding: 24px 30px;
  font-family: 'OpenSans_Condensed-Regular';
  font-size: 17px;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  box-shadow: 6px 6px rgba(125, 125, 125, 0.9);
  cursor: pointer;
}

.seamless {
  background-image: url("./images/underline.svg");
  background-position-x: 50%;
  background-position-y: 20%;
  background-repeat: no-repeat;
  background-size: 150%;
}

.wave {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 147px;
}

.wave .shape-fill {
  fill: #080066;
}

/* .most-popular-tag {
  color: #EFF3F7;
  letter-spacing: 1px;
  background-color: #4040ce;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 6px 9px;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: 16%;
  bottom: auto;
  left: 4%;
  right: auto;
  transform: rotate(-9deg);
} */

.quotes {
  width: 250px;
}

.lottie {
  width: 200px;
}
.lottieCanvasJson {
  width: 125px;
}

.lottieShip {
  width: 350px;
}

.lottieDiv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottieCanvas {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.remove-underline {
  text-decoration: none;
}

.main {
  width: 100%;
  height: 100vh;
}

.font-face-roboto {
  font-family: "Roboto";
}


.icons {
  color: #000;
  margin: 25px;
  font-size: 20px;
}

/* Input fields  */

#outlined-basic {
  color: #000;
}

input[type="email"] {
  color: black;
}

input[type="email"]:focus {
  color: black;
}

input[type="name"]:focus {
  color: black;
}
input[type="name"] {
  color: black;
}

input[type="number"]:focus {
  color: black;
}
input[type="number"] {
  color: black;
}

input[type="text"]:focus {
  color: black;
}

textarea[type="text"] {
  color: black;
}

textarea[type="text"]:focus {
  color: black;
}

.side-bar-logo {
  padding: 15px;
  width: 300px;
}


.burger_icon {
  padding-right: 18px;
  display: none;
}
.sidebar_closeIcon {
  right: 25px;
  top: 10px;
  position: absolute;
  font-size: 40px !important;
  cursor: pointer;
  color: #fff;
}
.sidebar_links_container {
  padding-left: 25px;
  margin-top: 10px;
  margin-bottom: 50px;
}
.sidebar_links {
  color: #000;
  font-family: "Roboto";
  margin-top: 30px;
}

.nav-link {
  font-family: 'OpenSans_Condensed-Regular';
  padding: 32px;
  letter-spacing: 1.5px;
  font-size: 16px;
  color: #eff3f7;
  font-weight: 500;
  transition: all 0.4s;

}

.nav-item {
  display: flex;
  column-gap: 40px;
}

.nav-link:hover,
.nav_link:focus {
  background-color: #080066;
  color: rgba(255, 255, 255, 1);
}


.nav-link-active {
  color: #fff;
  font-weight: bold;
}

.navigation {
  color: #eff3f7;
  align-items: center;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2;
  
}

.nav-bg {
  background-color: #000;
  width: 100%;
  height: 5.5rem;
}

.purple-text {
  color: "#4040ce"
}


/* Start gallery */

/* IDs */

#padding-text {
  padding-right: 100px;
  padding-left: 100px;
}

#padding-title {
  padding-right: 100px;
  padding-left: 100px;
}



/* End  gallery */

/* MEDIA QUERIES */

@media screen and (max-width: 1400px) {

  .servicesView {
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 600px;
    background-color: #eff3f7;
  }


  .nav-link {
    margin: 5px;
    letter-spacing: 1.5px;
    font-size: 12px;
    color: #eff3f7;
    font-weight: 500;
    transition: all 0.4s;
  }


}

@media screen and (max-width: 1000px) {
  .navlinks_container {
    display: none;
  }

  .site-logo {
    display: none;
  }

  .navigation {
    min-width: 350px;
    color: #eff3f7;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
  }
  .burger_icon {
    cursor: pointer;
    display: block;
    right: 20px;
    top: 30px;
    color: #eff3f7;
  }

  .servicesView {
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 660px;
    background-color: #eff3f7;
  }



 

  #padding-text {
    padding-right: 20px;
    padding-left: 20px;
  }

  .navbar_logo {
    display: none;
  }

  .wave {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 45px;
  }

  .wave .shape-fill {
    fill: #080066;
  }


 
}

@media screen and (max-width: 700px) {
 

  .servicesView {
    text-align: center;
    justify-content: center;
    /* width: 675px; */
    width: 100%;
    height: 660px;
    background-color: #d9d9da;
  }


  .wave {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .wave .shape-fill {
    fill: #080066;
  }

  #padding-text {
    padding-right: 20px;
    padding-left: 20px;
  }




}

@media screen and (max-width: 552px) {


  .servicesView {
    text-align: center;
    justify-content: center;
    margin: 0.3rem;
    /* width: 500px; */
    width: 100%;
    height: 500px;
    background-color: #d9d9da;
  }


  .wave {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .wave .shape-fill {
    fill: #080066;
  }

  #padding-text {
    padding-right: 5px;
    padding-left: 5px;
  }

  #padding-title {
    padding-right: 20px;
    padding-left: 20px;
  }

  .nav-bg {
    background-color: #000;
    width: 100%;
    height: 4rem;
  }
}

